import P5 from "p5";
// import P5 from 'https://cdn.jsdelivr.net/npm/p5@1.4.0/lib/p5.js';

export default class MyCircle {
	_p5: P5;
	_image: P5.Image;
	_originalPos: P5.Vector;
	_currentPos: P5.Vector;

	_visible: boolean;

	constructor(p5: P5, image: P5.Image, originalPos: P5.Vector) {
		this._p5 = p5;
		this._image = image;
		this._originalPos = originalPos;
		this._currentPos = originalPos;

		this._visible = true;
	}

	isOnRightPosition() {
		return this._originalPos.dist(this._currentPos) === 0;
	}

	isClicked(clickPos) {
		// Create mous pso conform pos
		const topLeft = this._p5.createVector(
			this._currentPos.x * this._image.width,
			this._currentPos.y * this._image.width
		);
		const bottomRight = this._p5.createVector(
			topLeft.x + this._image.width,
			topLeft.y + this._image.width
		);

		return (
			clickPos.x >= topLeft.x &&
			clickPos.x < bottomRight.x &&
			clickPos.y >= topLeft.y &&
			clickPos.y < bottomRight.y
		);
	}

	get image() {
		return this._image;
	}

	get currentPos() {
		return this._currentPos;
	}

	set currentPos(newPos: P5.Vector) {
		this._currentPos = newPos;
	}

	get visible() {
		return this._visible;
	}

	set visible(newVisibility: boolean) {
		this._visible = newVisibility;
	}
}
