import P5 from "p5";
import "p5/lib/addons/p5.dom";

import Board from "./Board";

var containerPuzzle = document.getElementById('container-layer');
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

// Creating the sketch itself
const sketch = (p5: P5) => {
	let puzzleEnabled = false;
	const size = containerPuzzle.offsetWidth;
	const myCircle: Board = new Board(p5, p5.createVector(0, 0), size/2, 4);
    
    // this._p5.loadImage(
    //     //   `https://picsum.photos/id/1015/${this._size}/${this._size}`,
    //       `https://obs.smehost.net/assetssmestaticcom-delabelsprod/haken/haken-fauna.png`,
    //     //   "../img/Haken_Fauna.jpg",
          
    //     // `https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Leucanthemum_vulgare_%27Filigran%27_Flower_2200px.jpg/1126px-Leucanthemum_vulgare_%27Filigran%27_Flower_2200px.jpg`,
    //       (img) => {
    //         img.resize(containerPuzzle.offsetWidth,containerPuzzle.offsetWidth);
    //         this.tileImage(img);
    //         this.shuffleTiles();
    //         this._image = img;
            
    //       }
    //     );
	// The sketch setup method
	p5.setup = () => {
		// Creating and positioning the canvas
		const canvas = p5.createCanvas(size/2, size/2);
		canvas.parent("app");
        // console.log(frame);

		// Configuring the canvas
		p5.background("black");
        const squareColor = p5.color(0, 0, 0, 10);
        // p5.rectMode(p5.CENTER)
        p5.noStroke()
        p5.fill(squareColor);
        p5.rect(0, 0, containerPuzzle.offsetWidth, containerPuzzle.offsetHeight);

		p5.mouseClicked = (evt: MouseEvent) => {
			if (urlParams.get('state') == null) return;
			const pos = p5.createVector(evt.offsetX, evt.offsetY);

			const clickedTile = myCircle.checkClick(pos);
			if (clickedTile) {
				myCircle.checkMove(clickedTile);
				myCircle.checkGameWon();
			}
		};
	};

	// The sketch draw method
	p5.draw = () => {
		myCircle.draw();
	};
};

new P5(sketch);
