import P5 from "p5";
import Tile from "./Tile";

var containerPuzzle = document.getElementById('container-layer');

export default class Board {
    _p5: P5;
    _pos: P5.Vector;
    _size: number;
    _image: P5.Image;
    _tiles: Tile[][];
    _numTiles: number;
    _tileSize: number;
    _frame: P5.Image;

    _won: boolean;
    _withHelp: boolean;

    constructor(
        p5: P5,
        atPosition: P5.Vector,
        size: number,
        numTiles: number,
        withHelp = true
    ) {
        this._p5 = p5;
        this._pos = atPosition;
        this._size = size;
        this._image = null;
        this._frame = null;
        this._numTiles = numTiles;
        this._tiles = new Array(this._numTiles);
        this._tileSize = this._size / this._numTiles;
        this._won = false;
        this._withHelp = withHelp;

        this._image = this._p5.loadImage(
            //   `https://picsum.photos/id/1015/${this._size}/${this._size}`,
            `https://obs.smehost.net/assetssmestaticcom-delabelsprod/haken/haken-fauna.png`,
            //   "../img/Haken_Fauna.jpg",

            // `https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Leucanthemum_vulgare_%27Filigran%27_Flower_2200px.jpg/1126px-Leucanthemum_vulgare_%27Filigran%27_Flower_2200px.jpg`,
            (img) => {
                
                img.resize(containerPuzzle.offsetWidth/2, containerPuzzle.offsetWidth/2);
                this.tileImage(img);
                this.shuffleTiles();
                this._image = img;

            }

        );

    }



    checkMove(tile: Tile) {
        let foundTile: Tile;

        this._tiles.forEach((row, y) => {
            row.find((theTile, x) => {
                const found = theTile === tile;

                if (found) {
                    foundTile = theTile;
                }

                return found;
            });
            // TODO: optimize
        });

        const checkPosLeft = this._p5.createVector(
            foundTile.currentPos.x - 1,
            foundTile.currentPos.y
        );
        const left = this._getTileAtPosition(checkPosLeft);
        const checkPosRight = this._p5.createVector(
            foundTile.currentPos.x + 1,
            foundTile.currentPos.y
        );
        const right = this._getTileAtPosition(checkPosRight);
        const checkPosTop = this._p5.createVector(
            foundTile.currentPos.x,
            foundTile.currentPos.y - 1
        );
        const top = this._getTileAtPosition(checkPosTop);
        const checkPosBottom = this._p5.createVector(
            foundTile.currentPos.x,
            foundTile.currentPos.y + 1
        );
        const bottom = this._getTileAtPosition(checkPosBottom);
        if (left && !left.visible) {
            this._switchTiles(left, foundTile);
        } else if (right && !right.visible) {
            this._switchTiles(right, foundTile);
        } else if (top && !top.visible) {
            this._switchTiles(top, foundTile);
        } else if (bottom && !bottom.visible) {
            this._switchTiles(bottom, foundTile);
        }
    }

    _switchTiles(tile1: Tile, tile2: Tile) {
        const temp = tile1.currentPos;
        tile1.currentPos = tile2.currentPos;
        tile2.currentPos = temp;
    }

    _getTileAtPosition(pos: P5.Vector) {
        let foundTile = null;
        this._tiles.forEach((row) => {
            const found = row.find(
                (theTile) =>
                    theTile.currentPos.x === pos.x && theTile.currentPos.y === pos.y
            );
            if (found) {
                foundTile = found;
            }
            // TODO: optimize
        });
        return foundTile;
    }

    checkClick(pos: P5.Vector) {
        let foundTile: Tile;
        this._tiles.forEach((row) => {
            const found = row.find((theTile) => theTile.isClicked(pos));
            if (found) {
                foundTile = found;
            }
            // TODO: optimize
        });

        return foundTile;
    }

    checkGameWon() {
        let allAtTheirPlaces = 0;

        this._tiles.forEach((row, y) => {
            row.forEach((theTile, x) => {
                const posOK = theTile.isOnRightPosition();
                if (posOK) allAtTheirPlaces += 1;
            });
        }); // TODO: Optimize

        if (allAtTheirPlaces === this._numTiles * this._numTiles) {
            this._tiles[0][0].visible = true;
            this._won = true;
            window.open('thankyou/', "_blank")
            this._p5.noLoop;
        }  
    }

    shuffleTiles() {
        // Shuffeling
        for (let i = 0; i < 3; i++) {
            this._tiles.forEach((cols) => {
                cols.sort((colA, colB) => Math.random() - 0.5);
            });
            this._tiles.sort((rowA, rowB) => Math.random() - 0.5);
        }

        // Now set the initial current positions
        this._tiles.forEach((row, y) => {
            row.forEach((col, x) => {
                const tile = this._tiles[y][x];
                tile.currentPos = this._p5.createVector(x, y);
            });
        });

        // Take the first tile as the needed free space
        const firstTile = this._tiles[0][0];
        firstTile.visible = false;
    }

    tileImage(img) {
        if (!img) return;

        for (let y = 0; y < this._numTiles; y++) {
            this._tiles[y] = [];

            for (let x = 0; x < this._numTiles; x++) {
                const tile = img.get(
                    x * this._tileSize,
                    y * this._tileSize,
                    this._tileSize,
                    this._tileSize
                );
                this._tiles[y][x] = new Tile(
                    this._p5,
                    tile,
                    this._p5.createVector(x, y)
                );
            }
        }
    }

    draw() {
        if (!this._image) return; // loading img is async, so we wait
        if (document.getElementById("puzzlesection").style.visibility == "")
            document.getElementById("puzzlesection").style.visibility = "inherit";


        const p5 = this._p5; // just for convenience
        // p5.imageMode(p5.CENTER);
        // p5.image(this._image, containerPuzzle.offsetWidth/2, containerPuzzle.offsetHeight/2);
        //     this.tileImage(this._image);
        //     // this.shuffleTiles();

        // p5.background(this._frame);
        // p5.background("white");
        const squareColor = p5.color(0, 0, 0, 50);
        // squareColor.setAlpha(10);
        // p5.rectMode(p5.CENTER)
        p5.noStroke()
        p5.fill(squareColor);
        p5.rect(0, 0, containerPuzzle.offsetWidth, containerPuzzle.offsetHeight);

        for (let y = 0; y < this._numTiles; y++) {
            for (let x = 0; x < this._numTiles; x++) {
                const tile = this._tiles[y][x];
                const rp = tile.isOnRightPosition();

                if (tile.visible) {
                    p5.image(
                        tile.image,
                        tile.currentPos.x * this._tileSize,
                        tile.currentPos.y * this._tileSize
                    );

                    // If help is needed, and we haven't won yet,
                    // we will mark the tiles that have been placed correctly here.
                    if (!this._won && this._withHelp && rp && tile.visible) {
                        p5.push();
                        p5.translate(
                            tile.currentPos.x * this._tileSize,
                            tile.currentPos.y * this._tileSize
                        );
                        p5.fill(p5.color(255, 0, 0, 160));
                        p5.triangle(
                            this._tileSize - 16,
                            0,
                            this._tileSize,
                            0,
                            this._tileSize,
                            16
                        );
                        p5.pop();
                    }

                    // Here we paint our grid, as long as we haven't won
                    if (!this._won) {
                        p5.push();
                        p5.stroke("black");
                        p5.noFill();
                        p5.rect(
                            tile.currentPos.x * this._tileSize,
                            tile.currentPos.y * this._tileSize,
                            this._tileSize,
                            this._tileSize
                        );
                        p5.pop();
                    }
                }
            }
        }

        // checkPresave();

        if (this._won) {
            p5.stroke("black");
            p5.fill("white");
            p5.textStyle(p5.BOLD);
            p5.textAlign(p5.CENTER, p5.CENTER);
            p5.textSize(34);

            p5.text("You did it!", 0, 0, p5.width, p5.height);
        }
    }
}
